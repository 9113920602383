/* You can add global styles to this file, and also import other style files */
/* You can add global styles to this file, and also import other style files */


body{
    width: 100%;
    height: calc(100vh - 0px);
}

/* styles.css */
.loading {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 1;
    transition: opacity 0.5s;
    z-index: 999;
  }
  
  .loading.hidden {
    opacity: 0;
    pointer-events: none;
  }
  
  .application-loading-container{
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 0px);
    backdrop-filter: blur(10px);
    background: rgba(0, 0, 0, 0.5);
  }

  .cursor-pointer {
    cursor: pointer;
  }
  
  /* width */
  ::-webkit-scrollbar {
    width: 0.1px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px white;
    border-radius: 10px;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: white;
    border-radius: 10px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: white;
  }
  
  ::-webkit-scrollbar {
    display: none;
  }
  button {
    cursor: pointer;
  }


  .mat-mdc-text-field-wrapper{
    height: 36px !important;
  }
  .mat-mdc-form-field-flex{
    height: 100% !important;
  }
  .mat-mdc-select-trigger{
    height: 36px !important;
  }
  .mat-mdc-form-field-infix{
    padding: 0px !important;
  }

  .heading-large{
    font-size: calc(36px* var(--head-fontSize, 1));
    line-height: calc(42px* var(--head-fontHeight, 1));
    font-family: var(--bs-head-font-family);
    font-weight: var(--bs-head-font-weight);
  }
  .body-large{
    font-size: calc(20px* var(--body-fontSize, 1));
    line-height: calc(30px* var(--body-fontHeight, 1));
    font-family: var(--bs-body-font-family);
    font-weight: var(--bs-body-font-weight);
  }
  .body-desc{
    font-size: calc(16px* var(--body-fontSize, 1));
    font-weight: 400;
    font-family: var(--bs-body-font-family);
    font-weight: var(--bs-body-font-weight);
  }
  button{
    font-size: 1.25rem;
    width: 100% ;
  }
  .heading-medium {
    font-size: calc(30px* var(--head-fontSize, 1));
    line-height: calc(38px* var(--head-fontHeight, 1));    
    font-family: var(--bs-head-font-family);
    font-weight: var(--bs-head-font-weight);
  }
  .heading-small{
    font-size: calc(20px* var(--head-fontSize, 1));
    line-height: calc(38px* var(--head-fontHeight, 1));  
    font-family: var(--bs-head-font-family);
    font-weight: var(--bs-head-font-weight);
  }
  // h1,h2,h3,h4,h5,h6

  h1,h2,h3,h4,h5,h6{
    font-family: var(--bs-head-font-family);
    font-weight: var(--bs-head-font-weight);
  }

  p,span{
    font-family: var(--bs-head-font-family);
    font-weight: var(--bs-head-font-weight);
  }
  @media only screen and (min-width : 768px){
    .heading-large{
      font-size: calc(46px* var(--head-fontSize, 1));
      line-height: calc(54px* var(--head-fontHeight, 1));
      font-family: var(--bs-head-font-family);
      font-weight: var(--bs-head-font-weight);
    }
    .heading-medium {
      font-size: calc(30px* var(--head-fontSize, 1));
      line-height: calc(38px* var(--head-fontHeight, 1));
      font-family: var(--bs-head-font-family);
      font-weight: var(--bs-head-font-weight);
    }
    .body-large{
      font-size: calc(16px* var(--body-fontSize, 1));
      line-height: calc(30px* var(--body-fontHeight, 1));
      font-family: var(--bs-body-font-family);
      font-weight: var(--bs-body-font-weight);
    }
  }
  
  @media only screen and (min-width : 1280px){
    .heading-large{
      font-size: calc(48px* var(--head-fontSize, 1));
      line-height: calc(65px* var(--head-fontHeight, 1));
      font-family: var(--bs-head-font-family);
      font-weight: var(--bs-head-font-weight);
    }
    .body-large{
      font-size: calc(16px* var(--body-fontSize, 1));
      line-height: calc(30px* var(--body-fontHeight, 1));
      font-family: var(--bs-body-font-family);
      font-weight: var(--bs-body-font-weight);
    }
    .heading-medium {
      font-size: calc(30px* var(--head-fontSize, 1));
      line-height: calc(38px* var(--head-fontHeight, 1));
      font-family: var(--bs-head-font-family);
      font-weight: var(--bs-head-font-weight);
    }
    .body-desc{
      font-size: calc(18px* var(--body-fontSize, 1));
      font-weight: 400;
      font-family: var(--bs-body-fontFamily);
      font-weight: var(--bs-body-font-weight);
    }
  }